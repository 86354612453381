import { useContext, lazy, Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import {
  LOGIN,
  SIGNUP,
  PRODUCTS,
  CATEGORY,
  DASHBOARD,
  ORDERS,
  ORDERS_INDEX,
  SETTINGS,
  CUSTOMERS,
  COUPONS,
  STAFF_MEMBERS,
  SITE_SETTINGS,
  CREATE_BUSINESS,
  INVOICES,
  INVOICES_FORM,
  INVOICE_MEMO_FORM,
  ADVANCED_SETTINGS,
  SUBSCRIPTIONS,
  WEBCHAT,
  isFreemium,
  LANDING_PAGE,
  INVENTOY_SETTINGS,
  PROPERTIES_INDEX,
  PROPERTIES,
  USERS,
} from "settings/constants";
import AuthProvider, { AuthContext } from "context/auth";
import { InLineLoader } from "components/InlineLoader/InlineLoader";
import { Modal } from "@redq/reuse-modal";

const Products = lazy(() => import("containers/Products/Products"));
const AdminLayout = lazy(() => import("containers/Layout/Layout"));
const Dashboard = lazy(() => import("containers/Dashboard/Dashboard"));
const Category = lazy(() => import("containers/Category/Category"));
const Orders = lazy(() => import("containers/Orders/Orders"));
const Settings = lazy(() => import("containers/Settings/Settings"));
const SiteSettingForm = lazy(() => import("containers/SiteSettingForm/SiteSettingForm"));
const StaffMembers = lazy(() => import("containers/StaffMembers/StaffMembers"));
const Customers = lazy(() => import("containers/Customers/Customers"));
const Coupons = lazy(() => import("containers/Coupons/Coupons"));
const Login = lazy(() => import("containers/Login/Login"));
const SignUp = lazy(() => import("containers/SignUp/SignUp"));
const NotFound = lazy(() => import("containers/NotFound/NotFound"));
const BusinessNewForm = lazy(() => import("containers/BusinessNewForm/BusinessNewForm"));
const Invoices = lazy(() => import("containers/Invoices/Invoices"));
const InvoiceForm = lazy(() => import("containers/InvoicesForm/InvoiceForm"));
const InvoiceMemoForm = lazy( () => import("containers/InvoiceMemos/InvoiceMemoForm"));
const AdvancedSetting = lazy(() => import("containers/AdvancedSettings/AdvancedSettings"));
const Subscriptions = lazy(() => import("containers/Subscriptions/Subscriptions"));
const WebChat = lazy(() => import("containers/WebChat/WebChat"));
const LandingPage = lazy(() => import("containers/LandingPage/LandingPage"));
const InventorySettings = lazy(() => import("containers/InventorySettings/InventorySettings"));
const Properties = lazy(() => import("containers/Properties/Properties"));
const Users = lazy(() => import("containers/Users/Users"));


/**
 *
 *  A wrapper for <Route> that redirects to the login
 * screen if you're not yet authenticated.
 *
 */

function PrivateRoute({ children, ...rest }) {
  const { isAuthenticated, customers } = useContext(AuthContext);
  const invoicePaths: Array<String> = [
    INVOICES,
    INVOICES_FORM,
    INVOICE_MEMO_FORM,
  ];
  var isInvoicePath = invoicePaths.includes(rest.path);
  var showInvoices = customers?.some(
    (customer) => customer.customer_country_code === "+506"
  );
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isInvoicePath ? (
          showInvoices && isAuthenticated ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          )
        ) : isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

/**
 *
 *  A wrapper for <Route> that redirects to the main
 * screen if you're not a super administrator.
 *
 */

 function PrivateAdminRoute({ children, ...rest }) {
  const { isAuthenticated, customers, user } = useContext(AuthContext);
  const invoicePaths: Array<String> = [
    INVOICES,
    INVOICES_FORM,
    INVOICE_MEMO_FORM,
  ];
  var isInvoicePath = invoicePaths.includes(rest.path);
  var showInvoices = customers?.some(
    (customer) => customer.customer_country_code === "+506"
  );
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isInvoicePath ? (
          showInvoices && isAuthenticated ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          )
        ) : isAuthenticated ? (
          user.is_super_admin ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: location },
              }}
            />
          )
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

/**
 *
 *  A wrapper for <Route> that redirects to the main
 * screen if you're don't have a basic subscription.
 *
 */

 function PrivateSubscriptionRoute({ children, ...rest }) {
  const { isAuthenticated, customers, user } = useContext(AuthContext);
  const invoicePaths: Array<String> = [
    INVOICES,
    INVOICES_FORM,
    INVOICE_MEMO_FORM,
  ];
  var isInvoicePath = invoicePaths.includes(rest.path);
  var showInvoices = customers?.some(
    (customer) => customer.customer_country_code === "+506"
  );
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isInvoicePath ? (
          showInvoices && isAuthenticated ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          )
        ) : isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const Routes = () => {
  return (
    <AuthProvider>
      <Modal>
        <Suspense fallback={<InLineLoader />}>
          <Switch>
            {
              process.env.REACT_APP_PROJECT_TYPE == "real_state" ? (
                <PrivateRoute
                  exact={true}
                  path={ PROPERTIES_INDEX }
                >
                  <AdminLayout>
                    <Suspense fallback={<InLineLoader />}>
                      <Properties />
                    </Suspense>
                  </AdminLayout>
                </PrivateRoute>
              ) : (
                <PrivateRoute
                  exact={true}
                  path={ ORDERS_INDEX }
                >
                  <AdminLayout>
                    <Suspense fallback={<InLineLoader />}>
                      <Orders />
                    </Suspense>
                  </AdminLayout>
                </PrivateRoute>
              )
            }
            <PrivateRoute path={USERS}>
              <AdminLayout>
                <Suspense fallback={<InLineLoader />}>
                  <Users />
                </Suspense>
              </AdminLayout>
            </PrivateRoute>
            <PrivateRoute path={PROPERTIES}>
              <AdminLayout>
                <Suspense fallback={<InLineLoader />}>
                  <Properties />
                </Suspense>
              </AdminLayout>
            </PrivateRoute>
            <PrivateRoute path={DASHBOARD}>
              <AdminLayout>
                <Suspense fallback={<InLineLoader />}>
                  <Dashboard />
                </Suspense>
              </AdminLayout>
            </PrivateRoute>
            <PrivateRoute exact={true} path={PRODUCTS}>
              <AdminLayout>
                <Suspense fallback={<InLineLoader />}>
                  <Products />
                </Suspense>
              </AdminLayout>
            </PrivateRoute>
            <PrivateRoute path={INVENTOY_SETTINGS}>
              <AdminLayout>
                <Suspense fallback={<InLineLoader />}>
                  <InventorySettings />
                </Suspense>
              </AdminLayout>
            </PrivateRoute>
            <PrivateRoute path={CATEGORY}>
              <AdminLayout>
                <Suspense fallback={<InLineLoader />}>
                  <Category />
                </Suspense>
              </AdminLayout>
            </PrivateRoute>
            <PrivateRoute path={ORDERS}>
              <AdminLayout>
                <Suspense fallback={<InLineLoader />}>
                  <Orders />
                </Suspense>
              </AdminLayout>
            </PrivateRoute>
            <PrivateRoute path={CUSTOMERS}>
              <AdminLayout>
                <Suspense fallback={<InLineLoader />}>
                  <Customers />
                </Suspense>
              </AdminLayout>
            </PrivateRoute>
            <PrivateRoute path={COUPONS}>
              <AdminLayout>
                <Suspense fallback={<InLineLoader />}>
                  <Coupons />
                </Suspense>
              </AdminLayout>
            </PrivateRoute>
            <PrivateRoute path={SETTINGS}>
              <AdminLayout>
                <Suspense fallback={<InLineLoader />}>
                  <Settings />
                </Suspense>
              </AdminLayout>
            </PrivateRoute>
            <PrivateRoute path={INVOICES}>
              <AdminLayout>
                <Suspense fallback={<InLineLoader />}>
                  <Invoices />
                </Suspense>
              </AdminLayout>
            </PrivateRoute>
            <PrivateRoute path={INVOICES_FORM}>
              <AdminLayout>
                <Suspense fallback={<InLineLoader />}>
                  <InvoiceForm />
                </Suspense>
              </AdminLayout>
            </PrivateRoute>
            <PrivateRoute path={INVOICE_MEMO_FORM}>
              <AdminLayout>
                <Suspense fallback={<InLineLoader />}>
                  <InvoiceMemoForm />
                </Suspense>
              </AdminLayout>
            </PrivateRoute>
            <PrivateRoute path={STAFF_MEMBERS}>
              <AdminLayout>
                <Suspense fallback={<InLineLoader />}>
                  <StaffMembers />
                </Suspense>
              </AdminLayout>
            </PrivateRoute>
            <PrivateRoute path={SITE_SETTINGS}>
              <AdminLayout>
                <Suspense fallback={<InLineLoader />}>
                  <SiteSettingForm />
                </Suspense>
              </AdminLayout>
            </PrivateRoute>
            <PrivateRoute path={CREATE_BUSINESS}>
              <AdminLayout>
                <Suspense fallback={<InLineLoader />}>
                  <BusinessNewForm />
                </Suspense>
              </AdminLayout>
            </PrivateRoute>
            <PrivateAdminRoute path={ADVANCED_SETTINGS}>
              <AdminLayout>
                <Suspense fallback={<InLineLoader />}>
                  <AdvancedSetting />
                </Suspense>
              </AdminLayout>
            </PrivateAdminRoute>
            <PrivateAdminRoute path={SUBSCRIPTIONS}>
              <AdminLayout>
                <Suspense fallback={<InLineLoader />}>
                  <Subscriptions />
                </Suspense>
              </AdminLayout>
            </PrivateAdminRoute>
            <PrivateSubscriptionRoute path={WEBCHAT}>
              <AdminLayout>
                <Suspense fallback={<InLineLoader />}>
                  <WebChat />
                </Suspense>
              </AdminLayout>
            </PrivateSubscriptionRoute>
            <Route path={`${LOGIN}`}>
              <Login />
            </Route>
            <Route path={`${LANDING_PAGE}`}>
              <LandingPage />
            </Route>
            <Route path={SIGNUP}>
              <SignUp />
            </Route>
            <Route>
              <NotFound />
            </Route>
          </Switch>
        </Suspense>
      </Modal>
    </AuthProvider>
  );
};

export default Routes;
