const config = {
  // aws_cloud_logic_custom: [
  //   {
  //     name: 'wasa-dev-2',
  //     endpoint: 'https://xb0zzeyvg9.execute-api.us-east-2.amazonaws.com/dev_2',
  //     region: 'us-east-1',
  //   },
  // ],
  API: {
    endpoints: [
      {
        name: 'wasa-dev',
        endpoint: 'https://wasa-dev.wasa.app',
      },
    ],
  },
};

export default config;
