import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { BaseProvider } from 'baseui';
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';
import { theme } from './theme';
import Routes from './routes';
import * as serviceWorker from './serviceWorker';
import './theme/global.css';
import "@redq/reuse-modal/lib/index.css";
import { QueryClient, QueryClientProvider } from 'react-query';
// Language translation messages
import { messages } from 'site-settings/site-translation/messages';
import { LanguageProvider } from 'context/language/language.provider';
import { IntercomProvider, useIntercom } from 'react-use-intercom';
import Amplify from 'aws-amplify';
import config from './aws-exports';

import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@material-ui/core';


Amplify.configure(config);

const client = new ApolloClient({
  uri: process.env.REACT_APP_API_URL,
  cache: new InMemoryCache(),
});

const queryClient = new QueryClient();

var themeMaterialUI = createMuiTheme({
  palette: {
    primary: {
      main: '#00C58D',
    },
    secondary: {
      main: '#161F6A',
    },
    text: {
      primary: '#161F6A',
      secondary: '#666D92',
    },
  },
  typography: {
    allVariants: {
      fontWeight: 700,
    },
    fontFamily: [
      'Lato',
      'Segoe UI',
      '-apple-system',
      'BlinkMacSystemFont',
      'Roboto',
      'sans-serif',
    ].join(','),
  },
});

themeMaterialUI = responsiveFontSizes(themeMaterialUI);

function App() {
  const engine = new Styletron();
  const INTERCOM_APP_ID = process.env.REACT_APP_WASA_INTERCOM;
  useEffect(() => {
    var string = (process.env.REACT_APP_TITLE || "Wasa Admin")
    document.title = string
  }, [])

  return (
    <ApolloProvider client={client as any}>
      <ThemeProvider theme={themeMaterialUI}>
        <LanguageProvider messages={messages}>
          <QueryClientProvider client={queryClient}>
            <StyletronProvider value={engine}>
              <BaseProvider theme={theme}>
                <IntercomProvider appId={INTERCOM_APP_ID}>
                  <BrowserRouter>
                    <Routes />
                  </BrowserRouter>
                </IntercomProvider>
              </BaseProvider>
            </StyletronProvider>
          </QueryClientProvider>
        </LanguageProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
