import React, { useState, useEffect }from 'react';
import { useIntercom } from 'react-use-intercom';

type AuthProps = {
  isAuthenticated: boolean;
  authenticate: Function;
  signout: Function;
  customer: any;
  userToken: string;
  isLocated:  boolean;
  setLocation: Function;
  makeLocated: Function;
  countryCodeLocated: string;
  customers: any;
  changeCustomer: any;
  setCountriesCodes: Function;
  isCountries: boolean;
  countries: any;
  user: any;
  addCustomer: Function;
  invoice: any;
  addCustomers: Function;
  updateUser: Function;
};

export const AuthContext = React.createContext({} as AuthProps);

const isValidUser = () => {

  const user = JSON.parse(localStorage.getItem('user'));
  // JWT decode & check token validity & expiration.
  if (user) return user;
  return null;
};


const isValidToken = () => {
  const token = localStorage.getItem('user_token');
  if (token) return token;
  return null;
};

const isValidCustomer = () => {
  const localCustomer = localStorage.getItem('customer')
  if (localCustomer !== 'undefined') {
    const customer = JSON.parse(localCustomer);
    if (customer) return customer;
  }
  
  return null;
};

const isCountryCode = () => {
  const countryCodeLocated = localStorage.getItem('country_code');
  if (countryCodeLocated) return countryCodeLocated;
  return null;
};

const isValidCustomers = () => {
  const customers = JSON.parse(localStorage.getItem('customers'));
  if (customers) return customers;
  return null;
};

const isValidCountries = () => {
  const countries = JSON.parse(localStorage.getItem('countries'));
  if (countries) return countries;
  return null;
};

const randomId = Math.random().toString(36).substring(7);

const AuthProvider = (props: any) => {

  const [user, setUser] = useState<any>(isValidUser());
  const [invoice, setInvoice] = useState<any>(isValidUser());
  const [userToken, setUserToken] = useState<string>(isValidToken());
  const [customer, setCustomer] = useState<any>(isValidCustomer());
  const [customers, setCustomers] =useState<any>(isValidCustomers());
  const [countryCodeLocated, setCountryCode] =useState<string>(isCountryCode());
  const [isAuthenticated, makeAuthenticated] = useState(
    (userToken && user && user?.from_real_estate) || (userToken && customer && customers && user)
  );
  const [countries, setCountries] = useState(isValidCountries());
  const [isLocated, makeLocated] = useState(
    countryCodeLocated != null,
  );
  const [isCountries, establishedCountries] = useState(countries != null);
  const { boot, shutdown } = useIntercom();

  function authenticate({ user, userToken, customer, customers }, cb) {
    makeAuthenticated(true);
    setUser(user);
    setUserToken(userToken);
    setCustomer(customer);
    setCustomers(customers);
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('user_token', userToken);
    localStorage.setItem('customer', JSON.stringify(customer));
    localStorage.setItem('customers', JSON.stringify(customers));
    cb();
  }
  function signout(cb) {
    makeAuthenticated(false);
    localStorage.removeItem('user');
    localStorage.removeItem('user_token');
    localStorage.removeItem('customer'); 
    localStorage.removeItem('customers');
    setUser(null);
    setTimeout(cb, 100);
  }
  function setLocation(countryCode) {
    setCountryCode(countryCode);
    localStorage.setItem('country_code', countryCode);
    makeLocated(true);
  }
  function changeCustomer({ customer }, cb) {
    setCustomer(customer);
    localStorage.setItem('customer', JSON.stringify(customer));
    cb();
  }
  function addCustomer({ customer, customers }, cb) {
    setCustomers(customers);
    setCustomer(customer);
    localStorage.setItem('customers', JSON.stringify(customers));
    localStorage.setItem('customer', JSON.stringify(customer));
    cb();
  }
  function addCustomers({ customers }, cb) {
    setCustomers(customers);
    localStorage.setItem('customers', JSON.stringify(customers));
    cb();
  }
  function setCountriesCodes(countriesCodes) {
    setCountries(countriesCodes);
    localStorage.setItem('countries', JSON.stringify(countriesCodes));
    establishedCountries(true);
  }

  function updateUser({ user }, cb) {
    if (user) {
      setUser(user);
      localStorage.setItem('user', JSON.stringify(user));
      cb();
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      if (isValidUser().support_status){
        shutdown();
        var name = user.first_name === '' ? '' : user.first_name;
        boot({ name: name, phone: user.phone_number, userId: user.id,  customAttributes: {request_site: window.location.href, business: customer.name } });
      }
    }else{
      shutdown();
      boot({ userId: randomId, customAttributes: {request_site: window.location.href }});
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        userToken,
        isAuthenticated,
        authenticate,
        signout,
        customer,
        setLocation,
        isLocated,
        makeLocated,
        countryCodeLocated,
        customers,
        changeCustomer,
        setCountriesCodes,
        isCountries,
        countries,
        user,
        addCustomer,
        invoice,
        addCustomers,
        updateUser,
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
