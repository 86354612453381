// **************** ROUTE CONSTANT START **************************
// General Page Section
export const DASHBOARD = '/dashboard';
export const PRODUCTS = '/products';
export const CATEGORY = '/category';
export const LOGIN = '/login';
export const SIGNUP = '/signup';
export const LOGOUT = '/logout';
export const ORDERS = '/orders';
export const ORDERS_INDEX = '/';
export const CUSTOMERS = '/customers';
export const COUPONS = '/coupons';
export const SETTINGS = '/settings';
export const ADVANCED_SETTINGS = '/advanced-settings';
export const STAFF_MEMBERS = '/staff-members';
export const SITE_SETTINGS = '/site-settings';
export const CREATE_BUSINESS ='/create-business'
export const INVOICES ='/invoices'
export const INVOICES_FORM ='/create-invoice/:docType'
export const INVOICE_MEMOS ='/invoice-memos'
export const INVOICE_MEMO_FORM ='/create-invoice-memo/:documentId'
export const SUBSCRIPTIONS = '/subscriptions'
export const WEBCHAT = '/web-chat'
export const LANDING_PAGE = '/landing-page'
export const INVENTOY_SETTINGS = '/products/inventory-settings'
export const PROPERTIES_INDEX = '/';
export const PROPERTIES = '/properties';
export const USERS = '/users';
// **************** ROUTE CONSTANT END **************************

export const CURRENCY = '$';

export const getMonths = function (intlInstance) {
  var months = [];
  for (var i = 0; i < 12; i++) {
    months.push(
      intlInstance.formatMessage({
        id: `month.${i + 1}`,
        defaultMessage: 'No Month',
      }),
    );
  }
  return months;
};

export const getSelectedMonths = function (intlInstance) {
  var months = [];
  for (var i = 1; i <= 12; i++) {
    months.push(
      {
        label: intlInstance.formatMessage({
          id: `month.${i}`,
          defaultMessage: 'No Month',
        }),
        value: i
      }
    )
  }
  return months;
};

export const getSelectedOrders = function (intlInstance) {
  return [
    {
      label: intlInstance.formatMessage({
        id: `order.desc`,
        defaultMessage: 'Best sellers',
      }),
      value: true
    },
    {
      label: intlInstance.formatMessage({
        id: `order.asc`,
        defaultMessage: 'Least sold',
      }),
      value: false
    }
  ]
};

export const statusSelectOptions = function (intlInstance) {
  return [
    {
      value: 'in_new',
      label: intlInstance.formatMessage({
        id: `orderStatus.in_new`,
        defaultMessage: 'New',
      }),
    },
    {
      value: 'received',
      label: intlInstance.formatMessage({
        id: `orderStatus.received`,
        defaultMessage: 'Received',
      }),
    },
    {
      value: 'delivered',
      label: intlInstance.formatMessage({
        id: `orderStatus.delivered`,
        defaultMessage: 'Delivered',
      }),
    },
    {
      value: 'cancelled',
      label: intlInstance.formatMessage({
        id: `orderStatus.cancelled`,
        defaultMessage: 'Cancelled',
      }),
    },
  ];
};

export const isFreemium = () => {
  if (process.env.REACT_APP_PROJECT_NAME === "wasa") {
    return "Freemium"
  }else if (process.env.REACT_APP_PROJECT_NAME === "keltek") {
    return "Plan acción (Freemium)"
  }
}
